import React, { useContext } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import { AuthContext } from '../../pageContext'

const ExitSurvey = ({ survey, index, QA, setOpenSurvey }) => {
  const { state, actions } = useContext(AuthContext)

  // Prsists the Survey
  const handleExit = async () => {
    // Update Users (Incomplete) Survey Response
    try {
      const surveyResponse = {
        surveyID: survey.id,
        userID: state.userId,
        questionsAndAnswers: QA,
        isComplete: false,
        index: index,
      }
      await axios.post(
        'https://akta-app-api.herokuapp.com/responses/save',
        surveyResponse
      )
    } catch (error) {
      console.log(error)
    }

    // Update Context Array for Incompleted Surveys
    const bufferArray = state.activeSurveys
    bufferArray.push(survey.id)
    actions.setActiveSurveys(bufferArray)

    // Update Users Survey List Statuses
    try {
      const updateSurveys = {
        email: state.user.email,
        activeSurveys: state.activeSurveys,
        completedSurveys: state.completedSurveys,
      }
      await axios.post(
        'https://akta-app-api.herokuapp.com/users/update-surveys',
        updateSurveys
      )
    } catch (error) {
      console.log(error)
    }

    // Close Survey and Go back to previous menue
    setOpenSurvey(false)
    navigate('/portal/', { replace: true })
  }

  return (
    <button className="exitButton" onClick={handleExit}>
      <div className="close-container">
        <div className="leftright"></div>
        <div className="rightleft"></div>
      </div>
    </button>
  )
}

export default ExitSurvey
