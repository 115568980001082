import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../pageContext'
import Button from '../Button'
import Survey from '../Survey'

const ContinueExistingSurvey = () => {
  const [surveyData, setSurveyData] = useState(undefined)
  const [openSurvey, setOpenSurvey] = useState(false)
  const [selectedSurvey, setSelectedSurvey] = useState(undefined)
  const { state } = useContext(AuthContext)

  useEffect(() => {
    const filterdSurveys = state.allSurveys.filter((survey) => {
      return state.activeSurveys.indexOf(survey.id) >= 0
    })

    setSurveyData(filterdSurveys)
  }, [state.activeSurveys])

  const handleSurveySelect = (survey) => {
    setSelectedSurvey(survey)
    setOpenSurvey(true)
  }
  return (
    <div className="surveyContentContainer">
      {!openSurvey && (
        <>
          {surveyData && surveyData.length === 0 && (
            <h3>You Do Not Have Any Surveys to Continue With!</h3>
          )}
          {surveyData &&
            surveyData.map((survey, index) => {
              return (
                <Button
                  key={survey.id}
                  className={index % 2 == 1 ? 'button-53' : 'button-54'}
                  type="button"
                  text={survey.name}
                  onClick={() => handleSurveySelect(survey)}
                />
              )
            })}
        </>
      )}
      {openSurvey && selectedSurvey && (
        <Survey
          survey={selectedSurvey}
          setOpenSurvey={setOpenSurvey}
          type="continue"
        />
      )}
    </div>
  )
}

export default ContinueExistingSurvey
