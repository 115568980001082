import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../pageContext'
import AnswerQuestion from './AnswerQuestion'
import SubmitSurvey from './SubmitSurvey'
import ExitSurvey from './ExitSurvey'
import Card from '../Card'

const Survey = ({ survey, setOpenSurvey, type }) => {
  const [index, setIndex] = useState(0)
  const [question, setQuestion] = useState(null)
  const [options, setOptions] = useState(null)
  const [showCompleteButton, setShowCompleteButton] = useState(false)
  const [QA, setQA] = useState([])

  const { state } = useContext(AuthContext)

  const handleContinuedSurvey = async () => {
    // GET Saved Index and Question/Answers
    try {
      const progessBody = {
        userID: state.userId,
        surveyID: survey.id,
      }
      const response = await axios.post(
        'https://akta-app-api.herokuapp.com/responses/progress',
        progessBody
      )

      if (response.data) {
        // Update State
        const { questionsAndAnswers, index } = response.data
        setQA(questionsAndAnswers)
        setIndex(index)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (type === 'continue') {
      handleContinuedSurvey()
    }
  }, [])

  useEffect(() => {
    const { questions } = survey
    if (index < questions.length) {
      setQuestion(questions[index].question)
      setOptions(questions[index].options)
    } else {
      setShowCompleteButton(true)
    }
  }, [index, survey])

  return (
    <Card>
      <ExitSurvey
        survey={survey}
        index={index}
        QA={QA}
        setOpenSurvey={setOpenSurvey}
      />
      <div className="surveyContent">
        <h2>{question && question}</h2>
        <ul className="opitonList">
          {options &&
            options.map((option) => {
              return (
                <li className="optionListItem" key={option.id}>
                  <AnswerQuestion
                    option={option.name}
                    question={question}
                    index={index}
                    setQA={setQA}
                    setIndex={setIndex}
                    QA={QA}
                  />
                </li>
              )
            })}
        </ul>
        <SubmitSurvey
          survey={survey}
          QA={QA}
          type={type}
          showCompleteButton={showCompleteButton}
        />
      </div>
    </Card>
  )
}

export default Survey
