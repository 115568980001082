import React from 'react'

const AnswerQuestion = ({ option, question, index, setQA, setIndex, QA }) => {
  // Answers A Question
  const handleAnswer = (answer, question) => {
    const bufferArray = QA
    const qna = {
      question: question,
      answer: answer,
    }
    bufferArray.push(qna)
    setQA(bufferArray)
    setIndex(index + 1)
  }

  return (
    <button
      className="optionButton"
      onClick={() => handleAnswer(option, question)}
    >
      {option}
    </button>
  )
}

export default AnswerQuestion
