import React, { useContext, useState } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import { AuthContext } from '../../pageContext'

const SubmitSurvey = ({ survey, QA, type, showCompleteButton }) => {
  const { state, actions } = useContext(AuthContext)
  const [completeButtonState, setCompleteButtonState] = useState(false)

  // Delay Timer
  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  // Completes the Survey
  const handleSubmit = async () => {
    // Update Users (Completed) Survey Response
    try {
      const surveyResponse = {
        surveyID: survey.id,
        userID: state.userId,
        questionsAndAnswers: QA,
        isComplete: true,
      }
      await axios.post(
        'https://akta-app-api.herokuapp.com/responses/save',
        surveyResponse
      )
    } catch (error) {
      console.log(error)
    }

    // Update Context Array for Completed Survey
    const bufferCompletedArray = state.completedSurveys
    bufferCompletedArray.push(survey.id)
    actions.setCompletedSurveys(bufferCompletedArray)

    // Update Context for Active Arrays
    if (type === 'continue') {
      const bufferActiveArray = state.activeSurveys.filter(
        (e) => e !== survey.id
      )
      actions.setActiveSurveys(bufferActiveArray)
    }

    // Update Users Survey List Statuses
    try {
      const updateSurveys = {
        email: state.user.email,
        activeSurveys: state.activeSurveys,
        completedSurveys: state.completedSurveys,
      }
      await axios.post(
        'https://akta-app-api.herokuapp.com/users/update-surveys',
        updateSurveys
      )
    } catch (error) {
      console.log(error)
    }

    setCompleteButtonState(true)
    await delay(3000)
    navigate('/take-a-survey/', { replace: true })
  }

  return (
    <>
      {/* {showCompleteButton && } */}
      <button
        className={
          completeButtonState
            ? 'submitButton active'
            : !showCompleteButton
            ? 'hideButton submitButton'
            : 'submitButton'
        }
        id="btn"
        onClick={handleSubmit}
      >
        <p id="btnText">{completeButtonState ? 'Thanks' : 'Submit'}</p>
        <div className="check-box">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path
              className="path"
              fill="transparent"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
      </button>
    </>
  )
}

export default SubmitSurvey
