import React from 'react'
import PropTypes from 'prop-types'
import './button.scss'

const Button = ({ className, link, text, type, onClick }) => {
  return (
    <>
      <div>
        <button className={className} onClick={onClick} role="button">
          {text}
        </button>
      </div>
    </>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  className: 'button-53',
  text: 'Button',
  onClick: () => {},
}

export default Button
