import React from 'react'
import './card.scss'

const Card = (props) => {
  const classList = ['cardContainer']
  let classes
  if (props.custom) {
    classList.push(props.custom)
  }

  classes = classList.join(' ')
  return (
    <div className={classes} {...props}>
      {props.children}
    </div>
  )
}

export default Card
