import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../pageContext'
import Button from '../Button'
import Survey from '../Survey'

const StartNewSurvey = () => {
  const [surveyData, setSurveyData] = useState(undefined)
  const [openSurvey, setOpenSurvey] = useState(false)
  const [selectedSurvey, setSelectedSurvey] = useState(undefined)
  const { state } = useContext(AuthContext)

  useEffect(() => {
    let filteredSurveys
    const combinedSurveyIDs = state.activeSurveys.concat(state.completedSurveys)

    if (combinedSurveyIDs.length === 0) {
      filteredSurveys = state.allSurveys
    } else {
      filteredSurveys = state.allSurveys.filter((survey) => {
        return combinedSurveyIDs.indexOf(survey.id) < 0
      })
    }

    setSurveyData(filteredSurveys)
  }, [state.activeSurveys])

  const handleSurveySelect = (survey) => {
    setSelectedSurvey(survey)
    setOpenSurvey(true)
  }

  return (
    <div className="surveyContentContainer">
      {!openSurvey && (
        <>
          {surveyData && surveyData.length === 0 && (
            <h3>Sorry We Do Not Have Any New Surveys Yet!</h3>
          )}
          {surveyData &&
            surveyData.map((survey, index) => {
              return (
                <Button
                  key={survey.id}
                  className={index % 2 == 1 ? 'button-53' : 'button-54'}
                  type="button"
                  text={survey.name}
                  onClick={() => handleSurveySelect(survey)}
                />
              )
            })}
        </>
      )}
      {openSurvey && selectedSurvey && (
        <Survey survey={selectedSurvey} setOpenSurvey={setOpenSurvey} />
      )}
    </div>
  )
}

export default StartNewSurvey
