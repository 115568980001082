import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AktaIcon from '../../images/aktaIcon'
import CTA from '../CTA'
import './sidePanel.scss'

const SidePanel = ({
  create,
  start,
  resume,
  user,
  background,
  setView,
  showModal,
  setShowModal,
}) => {
  return (
    <div className="sidepanel" style={{ background: background }}>
      <AktaIcon
        width="86.45"
        height="46"
        background={background}
        style={{ padding: '24px 0 0 18px' }}
      />
      <div className="line" />
      <div className="middlePanel">
        <div className="projectContainer"></div>
        <div className="ctaContainer">
          {create && (
            <CTA width="250px" size="14px" onClick={() => setView('create')}>
              Create Survey
            </CTA>
          )}
          {start && (
            <CTA width="250px" size="14px" onClick={() => setView('start')}>
              Start New Survey
            </CTA>
          )}
          {resume && (
            <CTA width="250px" size="14px" onClick={() => setView('continue')}>
              Continue Surveys
            </CTA>
          )}
        </div>
      </div>
      <div className="line" />
      {user && (
        <div className="userInfo">
          <div className="image"></div>
          <div className="nameContainer">
            <div className="name">
              {user.firstName} {user.lastName}
            </div>
            <div className="email">{user.email}</div>
          </div>
          <button
            className="moreOptions"
            onClick={() => setShowModal(!showModal)}
          >
            <div className="elipses" />
            <div className="elipses" />
            <div className="elipses" />
          </button>
        </div>
      )}
    </div>
  )
}

SidePanel.propTypes = {
  create: PropTypes.bool,
  start: PropTypes.bool,
  resume: PropTypes.bool,
  user: PropTypes.object,
  background: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}

SidePanel.defaultProps = {
  create: false,
  start: false,
  resume: false,
  user: {},
  background: '#F8F9FD',
  showModal: false,
  setShowModal: () => {},
}

export default SidePanel
