import React from 'react'
import './modal.scss'

const Modal = ({ setShowModal, children }) => {
  return (
    <>
      <div className="modalContainer" />
      <div className="modal">
        <button className="exit" onClick={() => setShowModal(false)}>
          X
        </button>
        {children}
      </div>
    </>
  )
}

export default Modal
