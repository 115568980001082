import React, { useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { AuthContext } from '../../pageContext'
import SurveyPanel from '../../components/SurveyPanel'
import SidePanel from '../../components/SidePanel'
import Modal from '../../components/Modal'
import CTA from '../../components/CTA'
import ContinueExistingSurvey from '../../components/PortalViews/ContinueExistingSurvey'
import StartNewSurvey from '../../components/PortalViews/StartNewSurvey'
import './portal.scss'

const Portal = () => {
  const { state, actions } = useContext(AuthContext)
  const [view, setView] = useState('default')
  const [showModal, setShowModal] = useState(false)

  const handleLogout = () => {
    actions.logout()
    navigate('/')
  }

  const data = useStaticQuery(graphql`
    query SurveyQuery {
      allContentfulSurvey {
        nodes {
          id
          name
          questions {
            options {
              name
              id
            }
            question
          }
        }
      }
    }
  `)

  useEffect(() => {
    const {
      allContentfulSurvey: { nodes: surveys },
    } = data

    actions.setAllSurveys(surveys)
  }, [data])

  return (
    <div className="portalContainer">
      <SidePanel
        start={true}
        resume={true}
        create={false}
        user={state.user}
        setView={setView}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="viewContainer">
        {view === 'default' && (
          <div className="surveyPanelContainer">
            <SurveyPanel
              title="Total Number of Active Surveys"
              color="#54C56B"
              number={state.activeSurveys.length}
            />
            <SurveyPanel
              title="Total Number of Completed Surveys"
              color="#EF476F"
              number={state.completedSurveys.length}
            />
            <SurveyPanel
              title="Total Number of Surveys"
              color="#14213D"
              number={state.allSurveys.length}
            />
          </div>
        )}

        {view === 'start' && <StartNewSurvey />}
        {view === 'continue' && <ContinueExistingSurvey />}
        {showModal && (
          <Modal setShowModal={setShowModal}>
            <div className="modalButtonContainer">
              <CTA height="30px" width="100%">
                Edit Profile
              </CTA>
              <CTA onClick={handleLogout} height="30px" width="100%">
                Log Out
              </CTA>
            </div>
          </Modal>
        )}
      </div>
    </div>
  )
}

export default Portal
