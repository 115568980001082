import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './surveyPanel.scss'

const SurveyPanel = ({ color, onClick, number, title }) => {
  const [date, setDate] = useState(undefined)

  useEffect(() => {
    const m = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const d = new Date()
    let day = d.getDate()
    let month = m[d.getMonth()]
    let year = d.getFullYear()

    const fullDate = `${month} ${day}, ${year}`
    setDate(fullDate)
  }, [])

  return (
    <button className="surveyButtonContainer" onClick={onClick}>
      <div className="outer" style={{ background: color }}>
        <div className="inner" style={{ background: color }}>
          {number}
        </div>
      </div>
      <div className="date">{date}</div>
      <div className="title">{title}</div>
    </button>
  )
}

SurveyPanel.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  number: PropTypes.number,
}

SurveyPanel.defaultProps = {
  color: '#FFB703',
  onClick: () => {},
  number: '0',
  title: 'Surveys',
}

export default SurveyPanel
